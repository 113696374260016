import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/rezgui-media-logo-white.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
               
            <h1 style={{ fontSize: "2.6em" }}>
              Bienvenue chez <span className="purple"> REZGUI MEDIA </span> votre partenaire de confiance pour la création de présences en ligne impactantes.
            </h1>
            <p className="home-about-body"> 
              <i>
                <b className="purple"> Sites e-commerce : </b>
              </i>Transformez vos idées commerciales en réalité avec des sites e-commerce conviviaux et performants.
              <br />
              <br />
              <i> <b className="purple">Portfolios professionnels :  </b> </i>
              Mettez en valeur votre expertise ou votre entreprise grâce à des portfolios personnalisés qui laisseront une impression durable.
              <br />
              <br />
              <i><b className="purple">Intégration sur les réseaux sociaux : </b></i>Augmentez votre visibilité et engagez votre audience en intégrant astucieusement vos sites web aux plateformes de médias sociaux.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Retrouvez-moi sur</h1>
            
            <ul className="home-about-social-links">
              {/* <li className="social-icons">
                <a
                  href="https://github.com/abdallah.rezgui"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://twitter.com/rezgui1st"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/abdallah-rezgui/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/abdallah.rezgui"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <p>
              N'hésitez pas à me <span className="purple">contacter</span> par e-mail <br/> 
                <span className="purple">info@rezguimedia.com </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
