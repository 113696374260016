import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Est un développeur freelance et gestionnaire de sa propre entreprise, <span className="purple">REZGUI MEDIA</span>. Sa passion inébranlable pour le <span className="purple">E-Commerce</span> et son désir de créer une <span className="purple">présence en ligne</span> impressionnante pour ses clients le démarquent dans l'industrie. Avec un talent indéniable dans des technologies clés telles que JavaScript, React, PHP, Symfony, <span className="purple">WordPress et PrestaShop</span>, Abdallah allie compétence technique et créativité pour façonner des expériences numériques exceptionnelles. Son engagement à apporter une valeur exceptionnelle à chaque projet est ce qui fait de lui un acteur incontournable dans le domaine du développement web.
            <br />
          </p>
                    
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
